<template>
  <div>
    <!-- <vs-card>
      <div class="aab-welcome">
    <h2 class="float-center">Bienvenu {{ user.person.firstname }}</h2>
    <p style=" font-size: large; text-align: center;">
      Le compte est sans engagement et vous permet de sauvegarder vos
      informations de souscription pour y revenir quand vous le souhaitez.
    </p>
    <vs-button class="mt-4" @click="gotoOffer">Découvrir</vs-button>
  </div>
  </vs-card> -->

    <div v-if="loadingRecoverSponsorCode">
      <div class="ml-2">
        <clip-loader
          :loading="loadingRecoverSponsorCode"
          color="primary"
        ></clip-loader>
      </div>
    </div>
    <div v-else class="m-4">
      <div class="pt-5 pb-5 justify-center">
        <div class="pb-3">
            <div slot="no-body" class="">
              <div class="aab-vie-title-desc">
                <h1 class="center text-center aab-vie-description">
                  Bienvenue {{ user.person.firstname }} !
                </h1>
              </div>
            </div>
        </div>
      </div>

      <div class="p-2">
        <vs-collapse>
          <vs-collapse-item class="aab-vie-collapse-item">
            <div slot="header">
              <div class="aab-vie-collapse-filter">Filtrage</div>
            </div>
            <div class="p-2">
              <vs-row vs-w="12" vs-align="center">
                <vs-col
                  class="pr-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                >
                  <span class="pl-1 pr-1">Du</span>
                  <div
                    class="inputx w-full flex justify-around"
                    style="align-items: center;"
                  >
                    <vs-input
                      v-model="startDate"
                      type="date"
                      class="aab-vie-disable-input-date inputx w-full mb-2 mt-2"
                    />
                  </div>
                </vs-col>
                <vs-col
                  class="pr-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                >
                  <span class="pl-1 pr-1">au </span>
                  <div
                    class="inputx w-full flex justify-around "
                    style="align-items: center;"
                  >
                    <vs-input
                      type="date"
                      v-model="endDate"
                      :min="startDate"
                      class="aab-vie-disable-input-date inputx w-full mb-2 mt-2"
                    />
                  </div>
                </vs-col>
                <vs-col
                  class="pr-4 mb-2 mt-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <multiselect
                      v-model="product"
                      :options="allProduct"
                      :close-on-select="true"
                      placeholder="--Choisir un produit--"
                      label="label"
                      track-by="label"
                      :selectLabel="global.selectLabel"
                      :selectedLabel="global.selectedLabel"
                      :deselectLabel="global.deselectLabel"
                    >
                    </multiselect>
                  </div>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" vs-align="center" vs-justify="center">
                <vs-col
                  class="pt-4 pb-2"
                  vs-type="flex"
                  vs-align="center"
                  vs-justify="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                >
                  <vs-button @click="resetFilter">
                    Réinitialiser
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>



    <div v-if="loadingStatCard">
      <div class="ml-2">
        <clip-loader
          :loading="loadingStatCard"
          color="primary"
        ></clip-loader>
      </div>
    </div>
 <div v-else>
      <div>
        <div class="pt-5 pb-5 flex justify-center">
          <h1>Statistiques</h1>
        </div>

        <div class=" pb-5 ">
          <vs-row vs-w="12" vs-align="center">
            <vs-col
              class="p-2"
              vs-lg="4"
              vs-md="4"
              vs-sm="6"
              :key="stat.label"
              v-for="stat in statistics"
            >
              <statistics-card-line
                hideChart
                icon="BarChart2Icon"
                icon-right
                :statistic="stat.value"
                :statisticTitle="stat.label"
              />
            </vs-col>
          </vs-row>
        </div>
      </div>
      <div>
        <div class="pt-5 pb-5 flex justify-center">
          <h1>Canal de souscription</h1>
        </div>

        <div class=" pb-5 ">
          <vs-row vs-w="12" vs-align="center">
            <vs-col
              class="p-2"
              vs-lg="4"
              vs-md="4"
              vs-sm="6"
              :key="stat.label"
              v-for="stat in subscriptionChannel"
            >
              <statistics-card-line
                hideChart
                icon="BarChart2Icon"
                icon-right
                :statistic="stat.value"
                :statisticTitle="stat.label"
              />
            </vs-col>
          </vs-row>
        </div>
      </div>

      <div>
        <div class="pt-5 pb-5 flex justify-center">
          <h1>Etat des souscriptions</h1>
        </div>

        <div class=" pb-5 ">
          <vs-row vs-w="12" vs-align="center">
            <vs-col
              class="p-2"
              vs-lg="4"
              vs-md="4"
              vs-sm="6"
              :key="stat.label"
              v-for="stat in subscriptionStatus"
            >
              <statistics-card-line
                hideChart
                icon="BarChart2Icon"
                icon-right
                :statistic="stat.value"
                :statisticTitle="stat.label"
              />
            </vs-col>
          </vs-row>
        </div>
      </div>

      <div>
        <div class="pt-5 pb-5 flex justify-center">
          <h1>Avis de souscription</h1>
        </div>

        <div class=" pb-5 ">
          <vs-row vs-w="12" vs-align="center">
            <vs-col
              class="p-2"
              vs-lg="4"
              vs-md="4"
              vs-sm="6"
              :key="stat.label"
              v-for="stat in subscriptionNotice"
            >
              <statistics-card-line
                hideChart
                icon="BarChart2Icon"
                icon-right
                :statistic="stat.value"
                :statisticTitle="stat.label"
              />
            </vs-col>
          </vs-row>
        </div>
      </div>

      <div>
        <div class="pt-5 pb-5 flex justify-center">
          <h1>Tickets</h1>
        </div>

        <div class=" pb-5 ">
          <vs-row vs-w="12" vs-align="center">
            <vs-col
              class="p-2"
              vs-lg="4"
              vs-md="4"
              vs-sm="6"
              :key="stat.label"
              v-for="stat in tickets"
            >
              <statistics-card-line
                hideChart
                icon="BarChart2Icon"
                icon-right
                :statistic="stat.value"
                :statisticTitle="stat.label"
              />
            </vs-col>
          </vs-row>
        </div>
      </div>
    </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import Multiselect from "vue-multiselect";
import global from "@/config/global";

export default {
  data() {
    return {
      isResetFilter: false,
      loadingStatCard: false,
      statistics: [],
      subscriptionChannel: [],
      subscriptionStatus: [],
      subscriptionNotice: [],
      tickets: [],
      productList: [],

      /*Statistics */
      product: "",
      startDate: "",
      endDate: "",
      /*statistics */

      loadingDownloadFile: false,
      loadingRecoverSponsorCode: false,
      error: false,

      global: {}
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("general", ["allProduct"])
  },
  components: {
    StatisticsCardLine,
    ClipLoader,
    Multiselect
  },
  mounted() {
    this.getStats({
      userId: this.user.id,
      userCode: this.user.userCode,
      startDate: this.startDate,
      endDate: this.endDate,
      productName: this.product.id ? this.product.id : "",
      by: "admin"
    });
  },
  created() {
    this.global = global;
  },
  methods: {
    ...mapActions("general", ["applyParrainStats"]),
    gotoOffer() {
      this.$router.push({ name: "Offer" });
    },

    async getStats(data) {
      let userStat = { ...data };
      this.loadingStatCard = true;
      try {
        let stats = await this.applyParrainStats(userStat);

        (this.statistics = stats.stats.statistics),
          (this.subscriptionChannel = stats.stats.subscriptionChannel),
          (this.subscriptionStatus = stats.stats.subscriptionStatus),
          (this.subscriptionNotice = stats.stats.subscriptionNotice),
          (this.tickets = stats.stats.tickets),
          (this.loadingStatCard = false);
        this.isResetFilter = false;
        this.error = false;
      } catch (err) {
        this.isResetFilter = false;
        this.loadingStatCard = false;
        this.error = true;
        this.errorMsg = "";
        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            this.acceptAlert(
              "danger",
              "STATISTIQUES",
              "Une erreur s'est produite"
            );
          }
        }
      }
    },

    resetFilter() {
      this.isResetFilter = true;
      this.startDate = "";
      this.endDate = "";
      this.product = "";
      this.getStats({
        userId: this.user.id,
        userCode: this.user.userCode,
        startDate: this.startDate,
        endDate: this.endDate,
        productName: this.product.id ? this.product.id : "",
        by: "admin"
      });
    }
  },
  watch: {
    product() {
      if (!this.isResetFilter) {
        this.getStats({
          userId: this.user.id,
          userCode: this.user.userCode,
          startDate: this.startDate,
          endDate: this.endDate,
          productName: this.product.id ? this.product.id : "",
          by: "admin"
        });
      }
    },
    startDate() {
      if (!this.isResetFilter) {
        this.getStats({
          userId: this.user.id,
          userCode: this.user.userCode,
          startDate: this.startDate,
          endDate: this.endDate,
          productName: this.product.id ? this.product.id : "",
          by: "admin"
        });
      }
    },
    endDate() {
      if (!this.isResetFilter) {
        this.getStats({
          userId: this.user.id,
          userCode: this.user.userCode,
          startDate: this.startDate,
          endDate: this.endDate,
          productName: this.product.id ? this.product.id : "",
          by: "admin"
        });
      }
    },
  }
};
</script>

<style>
.aab-welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
